import {CardContent, createStyles, makeStyles, Theme, Typography} from "@material-ui/core";
import React from "react";
import {GeoAsset} from "../models/GeoAsset";
import { GeoAssetMap } from "./GeoAssetMap";

type AssetMapProps = {
    assets: GeoAsset[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({   
    workingHeader: {
        textAlign: 'center',
        fontSize: '1.5rem',
      },
    workingHr: {
        border: `2px solid ${theme.palette.secondary.main}`,
        width: '10%',
        [theme.breakpoints.up('md')]: {
          width: '5%'
        }},
    footerText: {
            textAlign: 'center',
            fontSize: '1.0rem',
            color: '#212121',
            fontStyle: 'italic'
    }
}))

function AssetMap({assets}: AssetMapProps) {
    const classes = useStyles()
    
    return (<>
     <Typography className={classes.workingHeader} variant="h6">
        Some of the vehicles active right now
        <hr className={classes.workingHr} />
      </Typography>

        <CardContent style={{minHeight: "27vh", maxWidth: "70%", margin: "auto", filter: 'drop-shadow(0px 0px 30px #a7a7a7)'}}>
            <GeoAssetMap assets={assets} height={700} />
        </CardContent>  
        
       
        <p className={classes.footerText}>The vehicle locations on this map are anonymised and not exact</p>     

    </>)
}

export {AssetMap}
