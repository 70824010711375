/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles'
import { Typography, useMediaQuery, Theme, Container } from '@material-ui/core'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../style/slick.css'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    partnershipsContainer: {
      position: 'relative',
      padding: '0 50px 0px 50px'
    },
    logo: {
      margin: '5%',
      [theme.breakpoints.up('md')]: {
        margin: 0
      }
    },
    ey: {
      margin: '7% 15%'
    },
    amber: {
      margin: '25% 20% 0% 20%'
    },
    octopus: {
      margin: '30% 5% 5% 5%'
    },
    meef: {
      margin: '20% 5% 5% 5%'
    },
    gfi: {
      margin: '25% 5% 5% 5%'
    },
    levc: {
      marginTop: '8%',
      marginLeft: '15%'
    },
    calstart: {
      marginTop: '1%',
      marginLeft: '30%'
    },
    filMobile: {
      margin: '19% 5% 5% 5%'
    },
    samsara: {
      margin: '10% 10%'
    },
    urbanMobile: {
      margin: '20% 10%'
    },
    smmt: {
      margin: '0% 5%'
    },
    london: {
      margin: '16% 20%'
    },
    innovate: {
      marginTop: '5%'
    },
    workingHeader: {
      textAlign: 'center',
      fontSize: '1.5rem'
    },
    workingHr: {
      border: `2px solid ${theme.palette.secondary.main}`,
      width: '10%',
      [theme.breakpoints.up('md')]: {
        width: '5%'
      }
    },
    bvrla: {
      margin: '13% 20% 10% 5%'
    },
    gravis: {
      width: '50%',
      marginTop: '17%',
      marginLeft: '15%'
    },
    microsoft: {
      marginTop: '22%'
    },
    geotab: {
      marginTop: '19%',
      marginLeft: '10%'
    },
    weknow: {
      marginTop: '22%',
      marginLeft: '12%'
    },
    breathe: {
      marginTop: '22%',
      marginLeft: '12%'
    },
    colts: {
      marginTop: '15%',
      marginLeft: '18%'
    },
    sherbet: {
      marginTop: '15%'
    }
  })
)

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props
  return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props
  return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />
}

export default function Partnerships() {
  const classes = useStyles()
  const theme = useTheme()
  const biggerThanMobile = useMediaQuery(theme.breakpoints.up('md'))

  const settings: any = {
    infinite: true,
    centerMode: !biggerThanMobile,
    centerPadding: !biggerThanMobile ? '75px' : '0px',
    autoPlay: 1500,
    pauseAutoPlayOnHover: false,
    slidesToShow: biggerThanMobile ? 4 : 1,
    slidesToScroll: biggerThanMobile ? 4 : 1
  }

  if (biggerThanMobile) {
    settings.nextArrow = <SampleNextArrow />
    settings.prevArrow = <SamplePrevArrow />
  }
  return (
    <>
      <Typography className={classes.workingHeader} variant="h6">
        Working alongside
        <hr className={classes.workingHr} />
      </Typography>
      <Container maxWidth="lg" style={{ padding: 0 }}>
        <Slider {...settings}>
          <div>
            <a href="https://www.ey.com/" rel="noopener noreferrer" target="__blank">
              <StaticImage className={classes.ey} src="../img/logos/eyrectangle.png" alt="Ernst and Young Logo" />
            </a>
          </div>
          <div>
            <a href="https://www.microsoft.com/en-gb/" rel="noopener noreferrer" target="__blank">
              <StaticImage className={classes.microsoft} src="../img/logos/microsoft.png" alt="Microsoft Logo" />
            </a>
          </div>
          <div>
            <a href="https://www.amberinfrastructure.com/" rel="noopener noreferrer" target="__blank">
              <StaticImage className={classes.amber} src="../img/logos/logo-amber.png" alt="Amber logo" />
            </a>
          </div>
          <div>
            <a href="https://www.graviscapital.com/" rel="noopener noreferrer" target="__blank">
              <StaticImage className={classes.gravis} src="../img/logos/logo-gravis.png" alt="Gravis logo" />
            </a>
          </div>
          <div>
            <a href="https://www.bvrla.co.uk" rel="noopener noreferrer" target="__blank">
              <StaticImage className={classes.bvrla} src="../img/logos/bvrla.png" alt="BVRLA Logo" />
            </a>
          </div>
          <div>
            <a href="https://www.octopusinvestments.com/" rel="noopener noreferrer" target="__blank">
              <StaticImage
                className={classes.octopus}
                src="../img/logos/logo-octopus-investments.svg"
                alt="Octopus Investments logo"
              />
            </a>
          </div>
          <div>
            <a href="https://www.greenfinanceinstitute.co.uk/" rel="noopener noreferrer" target="__blank">
              <StaticImage className={classes.gfi} src="../img/logos/gfi2.png" alt="Green Finance Institute logo" />
            </a>
          </div>
          <div>
            <a
              href="https://www.london.gov.uk/what-we-do/environment/climate-change/zero-carbon-london/mayor-londons-energy-efficiency-fund"
              rel="noopener noreferrer"
              target="__blank"
            >
              <StaticImage className={classes.meef} src="../img/logos/meef.jpeg" alt="MEEF logo" />
            </a>
          </div>
          <div>
            <a href="https://www.fintechinnovationlab.com/" rel="noopener noreferrer" target="__blank">
              <StaticImage className={classes.filMobile} src="../img/logos/fil.png" alt="Fintech Innovation Lab logo" />
            </a>
          </div>
          <div>
            <a href="https://calstartconnect.org/index.cfm" rel="noopener noreferrer" target="__blank">
              <StaticImage className={classes.calstart} src="../img/logos/calstart.jpeg" alt="Calstart Logo" />
            </a>
          </div>
          <div>
            <a href="https://www.smmt.co.uk/" rel="noopener noreferrer" target="__blank">
              <StaticImage className={classes.smmt} src="../img/logos/smmt.png" alt="SMMT Logo" />
            </a>
          </div>
          <div>
            <a href="https://www.geotab.com" rel="noopener noreferrer" target="__blank">
              <StaticImage className={classes.geotab} src="../img/logos/Geotab.png" alt="Geotab Logo" />
            </a>
          </div>
          <div>
            <a href="https://www.samsara.com/" rel="noopener noreferrer" target="__blank">
              <StaticImage className={classes.samsara} src="../img/logos/samsara.png" alt="Samsara Logo" />
            </a>
          </div>
          <div>
            <a href="https://www.innovatefinance.com/" rel="noopener noreferrer" target="__blank">
              <StaticImage className={classes.innovate} src="../img/logos/innovate.png" alt="Innovate finance logo" />
            </a>
          </div>
          <div>
            <a href="https://www.urbanmovementlabs.com/" rel="noopener noreferrer" target="__blank">
              <StaticImage
                className={classes.urbanMobile}
                src="../img/logos/urban-movement-labs.png"
                alt="Urban Movement Labs Logo"
              />
            </a>
          </div>
          <div>
            <a href="https://www.londonandpartners.com/" rel="noopener noreferrer" target="__blank">
              <StaticImage
                className={classes.london}
                src="../img/logos/london-and-partners.png"
                alt="London and Partners Logo"
              />
            </a>
          </div>
          <div>
            <a href="https://www.breathe.cars" rel="noopener noreferrer" target="__blank">
              <StaticImage className={classes.breathe} src="../img/logos/breathe.png" alt="Breathe Logo" />
            </a>
          </div>
          <div>
            <a href="https://www.weknowlondon.com" rel="noopener noreferrer" target="__blank">
              <StaticImage className={classes.weknow} src="../img/logos/weknow.png" alt="WeKnow Logo" />
            </a>
          </div>
          <div>
            <a
              href="https://find-and-update.company-information.service.gov.uk/company/02632774/officers"
              rel="noopener noreferrer"
              target="__blank"
            >
              <StaticImage className={classes.colts} src="../img/logos/colts.png" alt="Colts Logo" />
            </a>
          </div>
          <div>
            <a href="https://www.sherbetlondon.com/" rel="noopener noreferrer" target="__blank">
              <StaticImage className={classes.sherbet} src="../img/logos/logo-sherbet.png" alt="Sherbet logo" />
            </a>
          </div>
        </Slider>
        <div style={{ height: '5vh' }} />
      </Container>
    </>
  )
}
