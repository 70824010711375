import { LayersControl, MapContainer, Marker, TileLayer } from "react-leaflet";
import { GeoAsset } from "../models/GeoAsset";
import React from "react";
import preloadedGeoAssets from '../data/preloadedAssets.json'

type GeoAssetMapProps = {
    assets: GeoAsset[]

}

const GeoAssetMap =({ assets }: GeoAssetMapProps) => {
    if (typeof LayersControl !== `undefined`) {
        return (
            <MapContainer style={{ height: 700 }} center={[51.505, -0.09]} zoom={11} scrollWheelZoom={false} maxZoom={12}>
                <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="Normal">
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Terrain">
                        <TileLayer
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />

                    </LayersControl.BaseLayer>
                </LayersControl>
                {
                    (assets ?? (preloadedGeoAssets as GeoAsset[]))?.map((x,index) => {
                        return x.latitude != null && x.longitude != null ? (
                            <Marker key={x.latitude + "_" + x.longitude + "_" + index} position={[x.latitude, x.longitude]} >
                            </Marker>
                        )
                            :
                            (
                                <>
                                </>
                            )
                    })
                }
            </MapContainer>)
    } else {
        return <></>
    }

}

export { GeoAssetMap }
