import * as React from 'react'
import { useEffect, useState } from 'react'
import IndexLayout from '../templates/IndexLayout'
import Hero from '../components/Hero'
import Partnerships from '../components/Partnerships'
import StatsStream from '../components/StatsStream'

import heroVideoDesktop from '../videos/hero-desktop-cropped-hd.webm'
import heroVideoMobile from '../videos/hero-mobile-cropped-hd.webm'
import heroPosterDesktop from '../videos/hero-desktop-thumbnail-hd.webp'
import heroPosterMobile from '../videos/hero-mobile-thumbnail-hd.webp'

import financialVideoDesktop from '../videos/financial-desktop-cropped-hd.webm'
import financialVideoMobile from '../videos/financial-mobile-cropped-hd.webm'
import financialPosterDesktop from '../videos/financial-desktop-thumbnail-hd.webp'
import financialPosterMobile from '../videos/financial-mobile-thumbnail-hd.webp'

import sustainabilityVideoDesktop from '../videos/sustainability-desktop-cropped-hd.webm'
import sustainabilityVideoMobile from '../videos/sustainability-mobile-cropped-hd.webm'
import sustainabilityPosterDesktop from '../videos/sustainability-desktop-thumbnail-hd.webp'
import sustainabilityPosterMobile from '../videos/sustainability-mobile-thumbnail-hd.webp'

import telematicsVideoDesktop from '../videos/telematics-desktop-cropped-hd.webm'
import telematicsVideoMobile from '../videos/telematics-mobile-cropped-hd.webm'
import telematicsPosterDesktop from '../videos/telematics-desktop-thumbnail-hd.webp'
import telematicsPosterMobile from '../videos/telematics-mobile-thumbnail-hd.webp'
import VehicleBrands from '../components/VehicleBrands'
import { getGeoAssets, refreshAggregateStats } from "../services/ReportingService";

import { GeoAsset } from '../models/GeoAsset'
import VideoComponent from '../components/VideoComponent'
import { AssetMap } from '../components/AssetMap'


import msCaptions from 'file-loader!../videos/Zeti_Final_VTT.vtt'
import msCover from '../videos/ms_video_cover.png'
import axios from 'axios'

const IndexTemplate: React.FunctionComponent = () => {
    const [geoAssets, setgeoAssets] = useState<GeoAsset[]>([]);
    useEffect(() => {
        const interval = setInterval(() => refreshData(), 5000)
        return () => {
            clearInterval(interval);
        };
    }, [])

    const refreshData = async () => {
        refreshAggregateStats().then(() => getGeoAssets().then((geoAssets) => {
            if (geoAssets != null) {
                setgeoAssets(geoAssets);
            }
        }));
    };

    return (
        <IndexLayout>
            <Hero
                topHero
                inverse
                videoSourceDesktop={heroVideoDesktop}
                videoSourceMobile={heroVideoMobile}
                videoPosterDesktop={heroPosterDesktop}
                videoPosterMobile={heroPosterMobile}
                mainText="Revolutionizing transport finance for good."
                bodyText={
                    <>
                        We help fleets convert to EVs by making it as simple, easy and transparent as paying for any
                        other utility with our patent-pending pay-per-mile financial technology.
                    </>
                }
                ctaLink="/operator-register-interest"
                ctaText="Register Interest"
                IncluceDownArrow={true}

            />
            <VideoComponent videoString='/videos/Zeti_Final.mp4' cover={msCover} captions={msCaptions} linkText="Zeti Partners with Microsoft" linkHref="https://customers.microsoft.com/en-us/story/1497637791225221774-zeti" title="Featured News" />
            <br />
            <VehicleBrands />
            <AssetMap assets={geoAssets} />
            <Hero
                videoSourceDesktop={financialVideoDesktop}
                videoSourceMobile={financialVideoMobile}
                videoPosterDesktop={financialPosterDesktop}
                videoPosterMobile={financialPosterMobile}
                mainText="Real-time insight"
                bodyText={
                    <>
                        Our patent pending ZERO platform offers unrivalled insight for both fleet operators and
                        financiers alike. Operators benefit from
                        usage-based billing to truly understand the profitability of their fleet. Financiers benefit
                        from real-time investment
                        performance reporting. Both benefit from a real-time granular data set of the environmental
                        benefits achieved by the investment
                        to report to their stakeholders.
                    </>
                }
                ctaLink="/contact-us/"
                ctaText="Contact Us"
                IncluceDownArrow={false}

            />
            <Partnerships />
            <Hero
                inverse
                videoSourceDesktop={telematicsVideoDesktop}
                videoSourceMobile={telematicsVideoMobile}
                videoPosterDesktop={telematicsPosterDesktop}
                videoPosterMobile={telematicsPosterMobile}
                mainText="Data driven"
                bodyText={
                    <>
                        Underpinning the ZERO platform are a range of trusted, integrated telematics solutions
                        delivering real-time vehicle behaviour
                        and diagnostic data to progressive fleet operators. These solutions enable an award-winning
                        pay-per-mile model that allows fleet
                        operators to optimize their cash flow and access a truly transparent cost of finance, and
                        financiers to understand the real
                        performance of their investment.
                    </>
                }
                ctaLink="/contact-us/"
                ctaText="Contact Us"
                IncluceDownArrow={false}
            />
            <Hero
                videoSourceDesktop={sustainabilityVideoDesktop}
                videoSourceMobile={sustainabilityVideoMobile}
                videoPosterDesktop={sustainabilityPosterDesktop}
                videoPosterMobile={sustainabilityPosterMobile}
                mainText="Sustainability at our core"
                bodyText={
                    <>
                        Our mission is to accelerate the shift to clean transport and to play a key role in tackling the
                        climate crisis. By only
                        choosing to finance cleaner forms of transport, such as electric vehicles, we ensure that our
                        mission is embedded in the core of
                        our business. We measure and present the impact that our fleet operators, financiers and
                        investors are driving in real-time
                        through our connected ZERO platform.
                        <StatsStream small={false} />
                    </>
                }
                IncluceDownArrow={false}

            />
        </IndexLayout>
    )
}

export default IndexTemplate
